import { HourGlassIcon } from "../../../components/icons/hourGlass";
import { useWeb3 } from "../../../context/web3.context";
import { CountdownTimer } from "./cooldownTime";
import "./styles.css";
import { useEffect, useState } from "react";

export const Memory = () => {
  const [game, setGame] = useState({
    gameStartedAt: null,
    flippedMemoryCards: 0,
    totalFlips: 0,
    totalTime: 0,
    loop: null,
    dimension: 6,
    gameFinished: false,
    gameFinishedAt: null,
    items: null,
  });
  const { account } = useWeb3();

  //   const [items, setItems] = useState(null);
  const [memoryCards, setMemoryCards] = useState(null);

  const refresh = () => {
    setGame({ ...game });
  };

  useEffect(() => {
    generateGame();
  }, [game]);

  const onMemoryCardClick = (i) => {
      if (!game.items)
        return;

      const item = game.items.find(a => a.index === i.index);
      if (!item.flipped) {
          flipMemoryCard(item);
        }
    };

  const shuffle = (array) => {
    const clonedArray = [...array];

    for (let index = clonedArray.length - 1; index > 0; index--) {
      const randomIndex = Math.floor(Math.random() * (index + 1));
      const original = clonedArray[index];

      clonedArray[index] = clonedArray[randomIndex];
      clonedArray[randomIndex] = original;
    }

    return clonedArray;
  };

  const pickRandom = (array, items) => {
    const clonedArray = [...array];
    const randomPicks = [];

    for (let index = 0; index < items; index++) {
      const randomIndex = Math.floor(Math.random() * clonedArray.length);

      randomPicks.push(clonedArray[randomIndex]);
      clonedArray.splice(randomIndex, 1);
    }

    return randomPicks;
  };

  const generateGame = () => {
    if (game.dimension % 2 !== 0) {
      throw new Error("The dimension of the board must be an even number.");
    }
    const table = Array.from({ length: 18 }, (_, index) => index + 1);
    const picks = pickRandom(table, (game.dimension * game.dimension) / 2);
    const tempItems = shuffle([...picks, ...picks]);
    const memoryCards = !game?.items ? (
      <div className="board">
        {tempItems.map((item) => renderMemoryCard({ index: item }))}
      </div>
    ) : (
      <div className="board">
        {game.items.map((item) => renderMemoryCard(item))}
      </div>
    );
    setMemoryCards(memoryCards);
  };

  const renderMemoryCard = (item) => (
    <div
      className={`memoryCard ${item.flipped ? "flipped" : ""} ${
        item.matched ? "matched" : ""
      }`}
      onClick={() => onMemoryCardClick(item)}
    >
      <div className="memoryCard-front"></div>
      <div
        className="memoryCard-back"
        style={{ background: `url(/assets/games/memory/${item.face}.png)` }}
      ></div>
    </div>
  );

  const startGame = () => {
    if (!account) {
      return;
    }

    const table = Array.from({ length: 18 }, (_, index) => index + 1);
    const picks = pickRandom(table, (game.dimension * game.dimension) / 2);
    const tempItems = shuffle([...picks, ...picks]);

    const at = new Date().getTime();
    const newGame = { ...game, gameStartedAt: at };

    setGame({
      ...newGame,
      gameFinishedAt: null,
      gameFinished: false,
      items: tempItems.map((i, index) => {
        return { index: index, face: i, flipped: false, matched: false };
      }),
    });
  };

  const flipBackMemoryCards = () => {
    const flippedMemoryCards = game.items.filter(
      (i) => i.flipped && !i.matched
    );
    flippedMemoryCards.forEach((i) => (i.flipped = false));
    setGame({ ...game, flippedMemoryCards: 0 });
  };

  const flipMemoryCard = (item) => {
    if (!game?.gameStartedAt) {
      return;
    }

    const flippedMemoryCards = game.flippedMemoryCards + 1;
    const totalFlips = game.totalFlips + 1;
    let g = {
        ...game,
        flippedMemoryCards: flippedMemoryCards,
        totalFlips: totalFlips,
      };

    if (flippedMemoryCards <= 2) {
      item.flipped = true;
    }

    if (flippedMemoryCards === 2) {
      const flippedMemoryCards = game.items.filter(
        (i) => i.flipped && !i.matched
      );

      if (flippedMemoryCards[0].face === flippedMemoryCards[1].face) {
        flippedMemoryCards[0].matched = true;
        flippedMemoryCards[1].matched = true;
    }

      setTimeout(() => {
        flipBackMemoryCards();
      }, 1000);
    }

    setGame(g);
    testFinish();
  };

  const calculateTimeDifference = () => {
    return Math.floor((game.gameFinishedAt - game.gameStartedAt) / 1000);
  };

  const formatTime = (seconds) => {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const remainingSeconds = seconds % 60;
    return `${hours ? `${hours}h` : ""} ${
      minutes ? `${minutes}min` : ""
    } ${remainingSeconds}s`;
  };

  const testFinish = () => {
    if (!game.items.some((a) => !a.flipped && !a.matched)) {
      setTimeout(() => {
        clearInterval(game.loop);
        setGame({ ...game, gameFinished: true, gameFinishedAt: new Date().getTime() });
      }, 1000);
    }
  };

  const renderButtonLabel = () => {
    if (!account)
        return "NOT CONNECTED";
    if (game.gameFinished)
        return "RETRY";
    if (game.gameStartedAt)
        return <HourGlassIcon />;

    return "START";
  }

  return (
    <div className="memory game">
      <div className="controls">
        <button
          onClick={startGame}
          className={game.gameStartedAt ? "disabled" : ""}
        >
          {renderButtonLabel()}
        </button>
        <div className="stats">
          <div className="moves">{game.totalFlips} moves</div>
          <div className="timer">
            {game.gameStartedAt ? (
              <CountdownTimer toDate={game.gameStartedAt} />
            ) : (
              "Time : 0s"
            )}
          </div>
        </div>
      </div>
      <div className={`${game.gameFinished ? "flipped" : ""} board-container`}>
        <div data-dimension={game.dimension}>{memoryCards}</div>
        {game.gameFinished && (
          <div className="win">
            <span className="win-text">
              You won!
              <br />
              with <span className="highlight">{game.totalFlips}</span> moves
              <br />
              under <span className="highlight">{formatTime(calculateTimeDifference())}</span> seconds
            </span>
          </div>
        )}
      </div>
    </div>
  );
};
