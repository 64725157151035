export const DexscreenerIcon = () => <a
        href="https://dexscreener.com/avalanche/0x349c52b76a862b626887cfcc7cf241aad07c4684"
        className="x-link"
        target="_blank"
        rel="noreferrer"
    >
    <div className="x">
        <svg width="24" height="25" viewBox="0 0 24 25" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_252_578)">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M14.2123 8.80423C14.9989 8.43767 15.9998 7.89808 17.0019 7.15878C17.2132 7.56884 17.2363 7.92667 17.1275 8.21561C17.0503 8.41924 16.9049 8.59636 16.7138 8.73726C16.5068 8.88954 16.2483 9.00041 15.9625 9.06033C15.4203 9.17448 14.7872 9.10783 14.2123 8.80423ZM14.3487 12.5601L15.3899 13.1222C13.2639 14.2358 12.6859 16.3037 11.9993 18.3178C11.3129 16.3037 10.7348 14.2358 8.60883 13.1222L9.65008 12.5601C9.75081 12.5245 9.83691 12.46 9.89603 12.3759C9.95516 12.2918 9.98428 12.1924 9.97923 12.0919C9.88383 10.2056 10.4288 9.37146 11.1639 8.84773C11.4275 8.66012 11.7154 8.56592 11.9993 8.56592C12.2833 8.56592 12.5711 8.66012 12.8349 8.84773C13.57 9.37146 14.1149 10.2056 14.0195 12.0919C14.0145 12.1924 14.0436 12.2918 14.1027 12.3759C14.1619 12.46 14.248 12.5245 14.3487 12.5601ZM11.9993 0.243652C13.2029 0.273852 14.4095 0.49222 15.4567 0.917021C16.1819 1.21157 16.8585 1.60064 17.4712 2.0659C17.7479 2.27593 17.9757 2.47884 18.2267 2.71243C18.9035 2.7343 19.8928 2.03153 20.352 1.37394C19.5617 3.79554 15.955 6.65524 13.458 7.74948C13.457 7.74908 13.4563 7.74852 13.4555 7.74804C13.0074 7.42826 12.5034 7.26836 11.9993 7.26836C11.4953 7.26836 10.9913 7.42826 10.5432 7.74804C10.5425 7.74844 10.5418 7.74916 10.5407 7.74948C8.04371 6.65524 4.43711 3.79554 3.64674 1.37394C4.10591 2.03153 5.09514 2.7343 5.77203 2.71243C6.02308 2.47892 6.25091 2.27593 6.52751 2.0659C7.1402 1.60064 7.81683 1.21157 8.54197 0.917021C9.58923 0.49222 10.7958 0.273852 11.9993 0.243652ZM9.78637 8.80423C8.99985 8.43767 7.99888 7.89808 6.99688 7.15878C6.7856 7.56884 6.76245 7.92667 6.87123 8.21561C6.94845 8.41924 7.09383 8.59636 7.28488 8.73726C7.49197 8.88954 7.75048 9.00041 8.03625 9.06033C8.57848 9.17448 9.21148 9.10783 9.78637 8.80423Z" fill="currentColor"/>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M18.0994 6.25286C18.651 5.73258 19.1371 5.1567 19.528 4.64282L19.7265 4.992C20.3656 6.18694 20.6976 7.37707 20.6976 8.71644L20.6963 10.8418L20.7083 11.9436C20.7545 14.6484 21.3808 17.3851 22.7993 19.8895L19.8313 17.6528L17.7312 20.8377L15.5249 18.8967L11.9993 24.2437L8.47374 18.8968L6.26754 20.8378L4.16745 17.6529L1.19934 19.8896C2.61791 17.3852 3.24414 14.6485 3.29051 11.9437L3.30251 10.8419L3.30114 8.71652C3.30114 7.37707 3.63311 6.18694 4.27237 4.99208L4.4708 4.6429C4.86165 5.15678 5.34765 5.73258 5.8994 6.25294L5.72711 6.58739C5.3924 7.23696 5.28157 7.96328 5.54231 8.65403C5.7104 9.09894 6.01717 9.48048 6.41265 9.77175C6.79665 10.0546 7.25111 10.2453 7.72691 10.3454C8.03685 10.4106 8.35263 10.4374 8.66634 10.4283C8.59314 10.8161 8.56117 11.2183 8.55911 11.6281L5.75934 13.1394L7.91985 14.2712C8.09254 14.3617 8.25693 14.4654 8.41134 14.5811C10.1926 16.0757 11.2635 20.4973 11.9994 22.6571C12.7355 20.4973 13.8063 16.0757 15.5876 14.5811C15.742 14.4653 15.9064 14.3617 16.0791 14.2712L18.2396 13.1394L15.4397 11.6281C15.4377 11.2183 15.4057 10.8161 15.3325 10.4283C15.6462 10.4374 15.962 10.4106 16.2719 10.3454C16.7477 10.2453 17.2023 10.0546 17.5862 9.77175C17.9816 9.48048 18.2885 9.09894 18.4565 8.65403C18.7173 7.96328 18.6064 7.23704 18.2717 6.58739L18.0995 6.25294L18.0994 6.25286Z" fill="currentColor"/>
            </g>
            <defs>
            <clipPath id="clip0_252_578">
                <rect width="24" height="24" fill="currentColor" transform="translate(0 0.243652)"/>
            </clipPath>
            </defs>
        </svg>
    </div>
</a>