export const Avatar = ({ address, selected, name }) => {
  const url = address ?? "/assets/avatar/default.jpg";

  return (
    <div className="avatarContainer">
      <img className={`avatar ${selected ? "selected" : ""}`} src={url} />
      <b>Default Chuck</b>
    </div>
  );
};
