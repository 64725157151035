import axios from "axios";

export const axiosInstance = axios.create({
  headers: {
    "Content-type": "application/json; charset=utf-8",
    // "Access-Control-Allow-Headers": "Content-Type",
    // "Access-Control-Allow-Origin": window.location.origin,
    // "Access-Control-Allow-Credentials": true
  },
});
// instance.defaults.baseURL = `${API_BASE_URL}`;

export const setAxiosAddress = (address) => {
  axiosInstance.defaults.headers.common["Authorization"] = address?.toLowerCase();
};

export default axiosInstance;
