import { Link } from "react-router-dom";

export const NavMenu = () => {
  const onBuy = () => {
    window.open(
      "https://traderjoexyz.com/avalanche/trade?inputCurrency=AVAX&outputCurrency=0xeF929078661a5a4b9493311f55b6df6D3Db77628",
      "_blank"
    );
  };
  
  return <>
    <Link to="/" onClick={onBuy} className="link-header">
      BUY
    </Link>
    <Link to="/games" className="link-header">
      PLAY
    </Link>
    <Link to="/leaderboard" className="link-header">
      LEADERBOARD
    </Link>
    <div className="link-header link-disabled">
      COLLECTIONS
    </div>
    {/* <Link to="cookies" className="link-header">STAKE</Link> */}
    {/* <Link to="/staking" className="link-header">
      STAKING
    </Link> */}
    {/* <Link to="/survivor" className="link-header">
      CLAIM
    </Link> */}
    </>
};
