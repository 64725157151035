import "./App.css";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Home from "./pages/Home";
import Layout from "./components/layout";
import Callback from "./pages/Callback";
import Survivor from "./pages/Survivor";
import Cookies from "./pages/Cookies";
import { Memory } from "./pages/Games/memory";
import Account from "./pages/Account";
import { Puzzle } from "./pages/Games/puzzle";
import Game from "./pages/Games";
import Leaderboard from "./pages/Leaderboard";

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route path="/" element={<Home />} />
          <Route path="/account" element={<Account />} />
          {/* <Route path="/chucklist" element={<Whitelist />} /> */}
          {/* <Route path="/survivor" element={<Survivor />} /> */}
          <Route path="/games" element={<Game />} />
          <Route path="/leaderboard" element={<Leaderboard />} />
          <Route path="/games/memory" element={<Memory />} />
          <Route path="/games/puzzle" element={<Puzzle />} />
          <Route path="/cookies" element={<Cookies />} />
        </Route>
        <Route path="/callback" element={<Callback />} />
      </Routes>
    </Router>
  );
}

export default App;
