import { useEffect, useState } from "react";
import { useWeb3 } from "../../context/web3.context";
import { authService } from "../../services/auth.service";

const Game = () => {
  const [label, setLabel] = useState("PARTICIPATE");
  const [userName, setUserName] = useState("");
  const { account } = useWeb3();

  return (
    <div className="page">
      <div className="part">
        <h1>PLAY</h1>
        <div className="flex flex-center">
          <div className="relative">
            <div className="card flex flex-column gap-20">
              <b>Challenge the community while playing games.</b>
              <ul>
                <li>A session costs x $CHUCK</li>
                <li>A session has 10 rounds</li>
                <li>Sessions have random games</li>
                <li>
                  The more you win, the more rewards you will earn at the end of
                  the month
                </li>
                <li>You can use Discord vocal chat</li>
                <li>Don't forget to have fun</li>
                <p>Coming soon ! </p>
              </ul>
              <button
                disabled={!account || !userName}
                style={{ cursor: !account ? "default" : "pointer" }}
              >
                {account ? label : "NOT CONNECTED"}
              </button>
            </div>
          </div>
          <div className="part-right">
            <img src="/banner7.png" alt="Chuck" width={500} async />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Game;
