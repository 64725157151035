/* eslint-disable jsx-a11y/alt-text */
import { useEffect, useState } from "react";
import MultipleValueTextInput from "react-multivalue-text-input";
import { useWeb3 } from "../../../context/web3.context";
import {
  balanceOf,
  getAllTokenIdStaked,
  getReward,
  stakeNft,
  unstakeNft,
} from "../../../services/nft.service";
import { ethers } from "ethers";

const StakeNftContent = ({ setModalSwitchIsOpen }) => {
  const [loading, setLoading] = useState(false);
  const [loadingUnstake, setLoadingUnstake] = useState(false);
  const [selectedNfts, setSelectedNfts] = useState([]);
  const [selectedNftUnstaked, setSelectedNftUnstaked] = useState([]);
  const [tokenIdStaked, setTokenIdStaked] = useState([]);
  const {
    account,
    chain,
    contracts,
    contractInitAvalanches,
    setCookies,
    setCookieWallet,
  } = useWeb3();

  const handleSelected = (tokenId) => {
    const selectedNftUnstakedClone = [...selectedNftUnstaked];
    if (selectedNftUnstakedClone?.includes(tokenId)) {
      setSelectedNftUnstaked(
        selectedNftUnstakedClone.filter((item) => item !== tokenId)
      );
    } else {
      selectedNftUnstakedClone.push(tokenId);
      setSelectedNftUnstaked(selectedNftUnstakedClone);
    }
  };

  const handleStakeNft = async () => {
    try {
      if (
        chain !==
        `0x${Number(process.env.REACT_APP_CHAIN_AVALANCHE).toString(16)}`
      ) {
        setModalSwitchIsOpen(true);
        return;
      }
      setLoading(true);
      await stakeNft(
        contracts.staking,
        contracts.chuckNft,
        selectedNfts,
        account
      );
      const value = await getReward(contractInitAvalanches.staking, account);
      const balanceCookie = await balanceOf(
        null,
        account,
        contractInitAvalanches.cookies
      );
      setTokenIdStaked([...tokenIdStaked, ...selectedNfts]);
      setSelectedNfts([]);
      setCookies(Number(ethers.utils.formatEther(value)).toFixed(2));
      setCookieWallet(Number(ethers.utils.formatEther(balanceCookie)));
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const handleUnstakeNft = async () => {
    try {
      if (
        chain !==
        `0x${Number(process.env.REACT_APP_CHAIN_AVALANCHE).toString(16)}`
      ) {
        setModalSwitchIsOpen(true);
        return;
      }
      setLoadingUnstake(true);
      await unstakeNft(contracts.staking, selectedNftUnstaked, account);
      const value = await getReward(contractInitAvalanches.staking, account);
      const balanceCookie = await balanceOf(
        null,
        account,
        contractInitAvalanches.cookies
      );
      const tokenIdStakedClone = [...tokenIdStaked];
      setTokenIdStaked(
        tokenIdStakedClone?.filter(
          (tokenId) => !selectedNftUnstaked.includes(Number(tokenId))
        )
      );
      setCookies(Number(ethers.utils.formatEther(value)).toFixed(2));
      setCookieWallet(Number(ethers.utils.formatEther(balanceCookie)));
      setSelectedNftUnstaked([]);
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingUnstake(false);
    }
  };

  useEffect(() => {
    if (account) {
      getAllTokenIdStaked(contractInitAvalanches.staking, account).then(
        (res) => {
          setTokenIdStaked(res);
        }
      );
    }
  }, [account]);
  return (
    <div className="relative mt-2 flex stakenft-content">
      <div className="card flex flex-column gap-20 width-full">
        <b>Boost your Cookie experience</b>
        <div>
          Stake your Chuck's NFTs and receive a 5% Cookie bonus for each NFT
          staked.
        </div>
        {/* <Avatar selected /> */}
        <div>
          <MultipleValueTextInput
            values={selectedNfts}
            onItemAdded={(item, allItems) => {
              setSelectedNfts(allItems);
            }}
            onItemDeleted={(item, allItems) => {
              setSelectedNfts(allItems);
            }}
            // label="Items"
            name="item-input"
            placeholder="Enter your token id, after click on ENTER to add another."
            className="mutliple-input"
          />
        </div>
        {/* <div className="list-nft">
        <img
          className={`avatar ${
            selectedNfts.includes(1) ? "selected-black" : ""
          } `}
          src="/assets/avatar/default.jpg"
          onClick={() => handleSelected(1)}
        />
        <img
          className={`avatar ${
            selectedNfts.includes(2) ? "selected-black" : ""
          } `}
          src="/assets/avatar/default.jpg"
          onClick={() => handleSelected(2)}
        />
      </div> */}
        <div className="text-center">
          <button
            onClick={!loading && !loadingUnstake ? handleStakeNft : null}
            style={{
              pointerEvents: "initial",
              paddingLeft: "20px",
              paddingRight: "20px",
            }}
            disabled={selectedNfts.length === 0}
          >
            {loading ? "LOADING..." : "STAKE NFT"}
          </button>
        </div>
      </div>
      {tokenIdStaked?.length > 0 && (
        <div className="card flex flex-column gap-20 width-full">
          <div className="list-nft">
            {tokenIdStaked?.map((tokenId) => (
              <img
                key={Number(tokenId)}
                className={`avatar ${
                  selectedNftUnstaked.includes(Number(tokenId))
                    ? "selected-black"
                    : ""
                } `}
                src="/assets/avatar/default.jpg"
                onClick={() => handleSelected(Number(tokenId))}
              />
            ))}
          </div>
          <div className="text-center">
            <button
              onClick={!loadingUnstake && !loading ? handleUnstakeNft : null}
              style={{
                pointerEvents: "initial",
                paddingLeft: "20px",
                paddingRight: "20px",
              }}
              disabled={selectedNftUnstaked.length === 0}
            >
              {loadingUnstake ? "LOADING..." : "UNSTAKE NFT"}
            </button>
          </div>
        </div>
      )}
    </div>
  );
};
export default StakeNftContent;
