export const DiscordIcon = () => <a
    href="https://discord.gg/cGKtC3Ss"
    className="x-link"
    target="_blank"
    rel="noreferrer"
    >
    <div className="x">
    <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
        d="M17.7123 20C17.7123 20 16.9726 19.1298 16.3562 18.3607C19.0479 17.6119 20.0753 15.9524 20.0753 15.9524C19.2329 16.4988 18.4315 16.8834 17.7123 17.1465C16.6849 17.5715 15.6986 17.8548 14.7329 18.0167C12.7603 18.381 10.9521 18.2798 9.41096 17.9964C8.23973 17.7738 7.23288 17.45 6.39041 17.1262C5.91781 16.9441 5.40411 16.7215 4.89041 16.4381C4.82877 16.3977 4.76712 16.3774 4.70548 16.3369C4.66438 16.3167 4.64384 16.2965 4.62329 16.2762C4.25342 16.0738 4.04794 15.9322 4.04794 15.9322C4.04794 15.9322 5.03425 17.5512 7.64383 18.3203C7.0274 19.0893 6.26712 20 6.26712 20C1.72603 19.8583 0 16.9238 0 16.9238C0 10.4072 2.9589 5.12515 2.9589 5.12515C5.91781 2.93945 8.73288 3.00017 8.73288 3.00017L8.93836 3.24302C5.23973 4.29539 3.53425 5.89419 3.53425 5.89419C3.53425 5.89419 3.9863 5.65133 4.74657 5.30729C6.9452 4.35611 8.69178 4.09301 9.41096 4.0323C9.53425 4.01206 9.63699 3.99182 9.76027 3.99182C11.0137 3.82992 12.4315 3.78945 13.911 3.95135C15.863 4.17397 17.9589 4.74063 20.0959 5.89419C20.0959 5.89419 18.4726 4.37634 14.9795 3.32397L15.2671 3.00017C15.2671 3.00017 18.0822 2.93945 21.0411 5.12515C21.0411 5.12515 24 10.4072 24 16.9238C24 16.9238 22.2534 19.8583 17.7123 20ZM8.15753 10.5489C6.9863 10.5489 6.06164 11.5608 6.06164 12.7953C6.06164 14.0298 7.00685 15.0417 8.15753 15.0417C9.32877 15.0417 10.2534 14.0298 10.2534 12.7953C10.274 11.5608 9.32877 10.5489 8.15753 10.5489ZM15.6575 10.5489C14.4863 10.5489 13.5616 11.5608 13.5616 12.7953C13.5616 14.0298 14.5068 15.0417 15.6575 15.0417C16.8288 15.0417 17.7534 14.0298 17.7534 12.7953C17.7534 11.5608 16.8288 10.5489 15.6575 10.5489Z"
        fill="currentColor"
        />
    </svg>
    </div>
</a>
