import { Link } from "react-router-dom"
import { DiscordIcon } from "./icons/discordIcon";
import { XIcon } from "./icons/xIcon";
import { DexscreenerIcon } from "./icons/dexscreenerIcon";
import { SnowtraceIcon } from "./icons/snowtraceIcon";
import { NavMenu } from "./navmenu";
import { AvaxIcon } from "./icons/avaxIcon";

export const Footer = () => {

    const onBuy = () => {
        window.open(
          "https://traderjoexyz.com/avalanche/trade?inputCurrency=AVAX&outputCurrency=0xeF929078661a5a4b9493311f55b6df6D3Db77628",
          "_blank"
        );
      };

    return <div className="footer">
        <div className="part">
            <div className="part-right">
                <AvaxIcon />
            </div>
            <div className="list-link-footer">
                <NavMenu />
            </div>
        </div>
        <div className="part">
            <div className="part-left">© <b>CHUCKAVAX.COM</b> 2024 — ALL RIGHTS RESERVED TO ITS COMMUNITY</div>
            <div className="part-right list-link-footer">
                <DiscordIcon />
                <DexscreenerIcon />
                <SnowtraceIcon />
                <XIcon />
            </div>
        </div>
    </div>
}