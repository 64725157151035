export const XIcon = () => <a
    href="https://twitter.com/ChuckAvax"
    className="x-link"
    target="_blank"
    rel="noreferrer"
    >
    <div className="x">
    <svg
        width="26"
        height="24"
        viewBox="0 0 26 24"
        xmlns="http://www.w3.org/2000/svg"
        >
        <path
            id="path1009"
            d="M0.0632662 0L10.1016 13.2374L0 24H2.27364L11.1177 14.5769L18.2633 24H26L15.3967 10.0182L24.7993 0H22.5256L14.3809 8.67818L7.8 0H0.0632662ZM3.40675 1.65155H6.96097L22.6561 22.3484H19.1019L3.40675 1.65155Z"
            fill="currentColor"
        />
        </svg>
    </div>
</a>
